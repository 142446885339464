function polyfillNodeListForEach () {
  /*
  * Add the forEach method to NodeList
  *
  * This is a polyfil for IE, which cannot iterate a node list.
  */
  if (typeof NodeList.prototype.forEach !== "function") {
    NodeList.prototype.forEach = Array.prototype.forEach
  }
}


export default polyfillNodeListForEach
