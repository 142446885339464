import polyfillNodeListForEach from "./polyfills/ElementIteration"

import GoogleMapsManager from "./plugins/GoogleMaps/GoogleMaps"
import SidebarTogglerManager from "./plugins/Sidebar/SidebarToggler"
import ScrollToTargetManager from "./plugins/ScrollToTarget/ScrollToTarget"

import "./index.scss"


function initPolyfills() {
  /*
  * Custom polyfills.
  */
  polyfillNodeListForEach()
}


function initPlugins() {
  /*
  * Initiate each plugin on the page.
  */
  new SidebarTogglerManager()
  new ScrollToTargetManager()
  new GoogleMapsManager()
}


initPolyfills()
window.addEventListener("load", initPlugins)
