import GoogleMapsAPI from "./GoogleMapsAPI"


export class GoogleMap {
  /*
  * Initialise a Google Map on an element.
  */
  constructor (el) {
    this.el = el
    this.longitude = parseFloat(el.dataset.longitude)
    this.latitude = parseFloat(el.dataset.latitude)
    this.zoom = parseFloat(el.dataset.zoom) || 16
    this.map = null
    this.client = null

    this.initMap = this.initMap.bind(this)

    GoogleMapsAPI.load(this.initMap)
  }

  initMap (googleAPI) {
    this.client = googleAPI.maps
    let mapOptions = {
      center: {
        lat: this.latitude,
        lng: this.longitude
      },
      zoom: this.zoom,
      scrollwheel: false
    }
    this.map = new this.client.Map(this.el, mapOptions)
    let location = new this.client.LatLng(this.latitude, this.longitude)
    let marker = new this.client.Marker({
      position: location,
      title: this.title
    })

    marker.setMap(this.map)
  }
}


class GoogleMapsManager {
  /*
  * Embed a goolge map wherever the google-map data attribute is specified.
  */
  constructor () {
    document
      .querySelectorAll("[data-google-map]")
      .forEach(function (el) {new GoogleMap(el)})
  }
}


export default GoogleMapsManager
