export class SidebarToggler {
  /*
  * Allows clicks to an element to control sidebar visibility.
  *
  * Reads the sidebar-toggler data property to determine how toggling works,
  * options are: "show", "close", "toggle"
  */
  constructor (el) {
    this.el = el
    this.toggleType = el.dataset.sidebarToggler || "toggle"

    this.toggleSidebar = this.toggleSidebar.bind(this)
    el.addEventListener("click", this.toggleSidebar)
  }

  toggleSidebar () {
    let toggleFunction

    if (this.toggleType === "show") {
      toggleFunction = function(el) {el.classList.remove("sidebar--hidden")}
    } else if (this.toggleType === "close") {
      toggleFunction = function(el) {el.classList.add("sidebar--hidden")}
    } else {
      toggleFunction = function(el) {el.classList.toggle("sidebar--hidden")}
    }

    document.querySelectorAll(".sidebar").forEach(toggleFunction)
  }
}


class SidebarTogglerManager {
  /*
  * Allow events on elements with sidebar-toggler data attr to toggle sidebar.
  */
  constructor () {
    document
      .querySelectorAll("[data-sidebar-toggler]")
      .forEach(function (el) {new SidebarToggler(el)})
  }
}


export default SidebarTogglerManager
