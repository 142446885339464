import scrollToElement from "../../utils/PageScroller"


export class ScrollToTarget {
  /*
  * Clicking an elemnt will scroll the page to its target
  *
  * Reads the scroll-to-target data property to get the target element id.
  */
  constructor (el) {
    this.el = el
    this.target = document.getElementById(el.dataset.scrollToTarget)

    this.scrollToTarget = this.scrollToTarget.bind(this)
    el.addEventListener("click", this.scrollToTarget)
  }

  scrollToTarget () {
    scrollToElement(this.target)
  }
}


class ScrollToTargetManager {
  /*
  * Allow events on elements with scroll-to-target data attr to scroll down.
  */
  constructor () {
    document
      .querySelectorAll("[data-scroll-to-target]")
      .forEach(function (el) {new ScrollToTarget(el)})
  }
}


export default ScrollToTargetManager
